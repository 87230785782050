import * as React from 'react'
import NavBar from '../Navbar'

import './style.css'

// ... import statements

const Footer = () => {

    return(
      <footer className="footer">
        Made by <a href="https://www.peter.com.ar" target="_blank" rel="noreferrer" >Peter</a> with love
      </footer>      
    )
}

const LayoutComponent = ({ section, children }) => {

  return (
    <div id="root" className="layout">
        <NavBar section={section}/> 
        {children}
        <Footer />
    </div>      
  )

}

export default LayoutComponent