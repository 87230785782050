import React, { useState, useEffect } from 'react'
import { createContext } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa'
import { navigate } from "gatsby"

import styled from 'styled-components';

import { IconContext } from 'react-icons/lib'
import { StaticImage } from 'gatsby-plugin-image'
import { 
    HeaderSection,
    Nav, 
    NavbarContainer, 
    NavLogo, 
    MobileIcon, 
    NavMenu,
    NavItem,
    NavText,
    NavTextBig
} from './NavbarElements'
import NavLinks from './NavLinks'
import { isLoggedIn, logout } from "../../services/auth"

const ReactiveNavLinks = styled.a`
    color: ${({ isactual }) => (isactual ? "#ff4040" : "#141414") }; ;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 2rem 2rem;
    height: 100%;
    background: white;

    &:hover { 
        color: #ff4040;
        transition: all 0.3s ease;
    }  

    @media screen and (max-width: 960px) {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;

        &:hover { 
            color: #ff4040;
            transition: all 0.3s ease;
        }
    }
`

const ImageWraper = styled.a`


    @media screen and (max-width: 960px) {
        margin-top: 20px;
        text-align: center;
    }
`

const SectionContext = createContext('home')


const NavBar = ({ section })  => {

    const [currentSection] = useState(section);

    const [click, setClick] = useState(false)
    const [scroll, setScroll] = useState(false)

    const handleClick = () => setClick(!click)

    const changeNav = () => {
      if (window.scrollY >= 80) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    }


    useEffect(()=>{
      changeNav();
      window.addEventListener("scroll", changeNav)
    }, [])


    return (
      <SectionContext.Provider value={currentSection}>
        <IconContext.Provider value={{color: "#141414"}} >
            <NavLogo to="/">
              <HeaderSection >
                <ImageWraper>
                  <StaticImage src='../../images/hoja-left.png' width={250} alt="" />
                </ImageWraper>       
                <NavText>J&P</NavText>
                <NavTextBig>Jasmin & Pedro</NavTextBig>
                <ImageWraper>
                  <StaticImage src='../../images/hoja-right.png' width={250} alt="" />
                </ImageWraper>                
              </HeaderSection>              
            </NavLogo>             
            <Nav active={scroll} click={click}>
              <NavbarContainer>
                <MobileIcon onClick={handleClick}>
                  { click ? <FaTimes/> : <FaBars /> }                  
                </MobileIcon>
                <NavMenu onClick={handleClick} click={click} >
                  <NavItem>
                    <NavLinks isactual={section && section === 'home' ? true : false} to="/" >HOME</NavLinks>
                  </NavItem>                                   
                  { 
                    isLoggedIn() ?
                    <>
                    <NavItem>
                      <NavLinks isactual={section && section === 'memories' ? true : false} to="/memories">Memories</NavLinks>
                    </NavItem>
                    <NavItem>
                      <NavLinks isactual={section && section === 'wedding' ? true : false} to="/wedding">Wedding</NavLinks>
                    </NavItem>                       
                    <NavItem>
                      <NavLinks isactual={section && section === 'events' ? true : false} to="/events">Events</NavLinks>
                    </NavItem>                                    
                    <NavItem>
                      <NavLinks isactual={section && section === 'travel' ? true : false} to="/travel">Travel</NavLinks>
                    </NavItem>                   
                    </>
                    : null
                  }           
                  <NavItem>
                      <NavLinks isactual={section && section === 'register' ? true : false} to="/register">RSVP</NavLinks>
                    </NavItem>                                                        
                  <NavItem>
                        { isLoggedIn() ? (
                          <ReactiveNavLinks
                            href="/"
                            onClick={event => {
                              event.preventDefault()
                              logout(() => navigate(`/app/login`))
                            }}
                          >
                            Logout
                          </ReactiveNavLinks>
                        ) : 
                        <NavItem>
                          <NavLinks to="/app/login">Login</NavLinks>
                        </NavItem>                           
                        }
                  </NavItem>                                                                
                </NavMenu>
              </NavbarContainer>
            </Nav>
        </IconContext.Provider>
      </SectionContext.Provider>
    )
}

export default NavBar