import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';

import Layout from '../../../components/Layout'
import Grid from '../../../components/Helpers/Grid'

const DestinationWraper = styled.div`
  border: 4px solid blue;
  text-align: center;
  margin: 0 auto;
  border: 2px dashed #dddddd;    
  padding: 30px;
  margin-bottom: 20px;
  height: auto;
  width: 80vw;  
`

const ColumnWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
`

const ProductCard = styled.div`
  display: block;
  flex-direction: column;
  margin-bottom: 24px;
  //border: 1px solid red;
  cursor: pointer;
  justify-content: flex-start;
  text-align: center;
  img {
    margin-bottom: 16px;
    height: 300px; 
    width: 300px;
    object-fit: cover; 
    border-radius: 50%;
    //border: 4px solid whitesmoke;
  }
  h3 {
    font-size: 24px;
  }
`



const Destinations = () => {

  return (
      <DestinationWraper>
        <Grid columns={1} style={{ gridColumnGap: "32px", marginBottom: 10, textAlign: "center" }}>
          <h1>Travel Information</h1>
          <p>We know traveling to our wedding could be challanging, here you will find all the information you need to be able to be with us.</p>
        </Grid>
      </DestinationWraper>
    )
}

const Description = () => {

  return (
      <DestinationWraper>
        <Grid columns={1} style={{ gridColumnGap: "32px", marginBottom: 10, textAlign: "center" }}>
          <h1>Buenos Aires</h1>
        </Grid>        
        <Grid columns={3} style={{ textAlign: "center" }}>           
          <ColumnWrapper>
            <ProductCard>
              <StaticImage src="../../../images/travel/flight.jpg" layout="constrained"   width={500}  height={500} />
              <h2>Flights</h2>
              <p>Best tips to book your flight</p>
            </ProductCard>
          </ColumnWrapper>
          <ColumnWrapper>
            <ProductCard>
              <StaticImage src="../../../images/travel/wheretostay.jpg"  layout="constrained" width={1200}  height={1200} />
              <h2>Where to stay</h2>
              <p>Hotel options in the city that never sleeps</p>
            </ProductCard>
          </ColumnWrapper>          
          <ColumnWrapper>
            <ProductCard>
              <StaticImage src="../../../images/travel/whattodo.jpg"  layout="constrained" width={1200}  height={1200} />
              <h2>What to see</h2>
              <p>Hotel options in the city that never sleeps</p>
            </ProductCard>
          </ColumnWrapper>npm
          <ColumnWrapper>
            <ProductCard>
              <StaticImage src="../../../images/travel/obelisco_square.jpg"  layout="constrained" width={1200}  height={1200} />
              <h2>Where to eat and drink</h2>
              <p>Hotel options in the city that never sleeps</p>
            </ProductCard>
          </ColumnWrapper>                         
        </Grid>        
      </DestinationWraper>
    )
}

const Page = () => {

  return (
    <Layout section="travel" pageTitle="Travel">
      <Destinations />
      <Description />
    </Layout>
  )
}

export default Page