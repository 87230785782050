
export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const setUser = user =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))


export const handleLogin = ({ username, password }) => {

  // const query = graphql(
  //   ` api {
  //       users {
  //         name
  //         surname
  //       }
  //     }
  //  `,
  //  {fileName: "knight.jpg"}
  // )


  // console.log('hello!');
  // console.log(query);


  if (password === `love`) {
    return setUser({
      username: `love`,
      name: `Pedro`,
      email: `pedro@jasminypedro.com`,
    })
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!user.username
}

export const logout = callback => {
  setUser({})
  callback()
}