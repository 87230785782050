import styled from 'styled-components'
import { DiScala } from 'react-icons/di'
import { Link } from 'gatsby'


export const NavLogo = styled(Link)`
  display: flex;
  width: auto;
  flex-wrap: wrap;
  align-content: center;
  cursor: pointer;
  text-decoration: none;
 //  border: 5px solid blue;
`


export const HeaderSection = styled.div`

  /* center */
  display: flex;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0px;
  position: relative;  


  font-family: "White Garden Wide Regular";
  font-size: 100px;  
  font-weight: 500; 
  
  
  color: black; 
  text-align: center;  

  &:hover {
    color : green
  }  /* mouse over link */

    
`

export const Nav = styled.nav`
  background: ${({ active }) =>
    active
    ? "#fff" 
    : "linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255,0) 100% )"};
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  /*border:  5px solid red;*/

  @media screen and (max-width: 960px) {
    background: ${({click}) => (click ? "#fff" :
    "transparent")};
    transition: 1.8s all ease;
    position: fixed;
    width: 100%;
  }
`


export const NavbarContainer = styled.div`
  display: flex; 
  justify-content: space-between;
  height: 80px;
  width: 100%;
  z-index: 1;  
`


export const NavIcon = styled(DiScala)`
  margin: 0 0.5rem 0 2rem;
  color: ${({ click }) => (click ? "#141414" : "#fff") };
`


export const NavText = styled.div`
  display: none;
  padding-left:20px;
  padding-right:20px;

  @media screen and (max-width: 960px) {
      display: flex;
      font-size: 80px;
  }
`

export const NavTextBig = styled.div`
  display: flex;
  padding-left:20px;
  padding-right:20px;  

  @media screen and (max-width: 960px) {
      display: none;
  }
`

export const MobileIcon = styled.div`
  display: none;
  color: #fff;

  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`



export const NavMenu = styled.div`

  /*border: 5px solid green;*/

  font-family: "Fanwood Text";
  font-weight: 400;
  font-style: normal;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 18.2px;

  display: flex;
  width: auto;

  height: auto;
  margin: 0 auto;

  position: relative;
  


  @media screen and (max-width: 960px) {
    display: absolute;
    flex-direction: column;
    width: 100%;
    height: auto;
    position: absolute;
    top: ${({ click }) => (click? "0" : "-1000px") };
    opacity: 0.97;
    transition: all 0.2s ease;
    background: #fff;
  }
`

export const NavItem = styled.div`
  height: 50px;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`