import React from 'react';
import styled from 'styled-components';

const GridWrapper = styled.section`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};

  @media screen and (max-width: 960px) {
    display: block; 
  }
`

const Grid = ({columns, children, style}) => {
  return (
    <GridWrapper columns={columns} style={style}>
      {children}
    </GridWrapper>
  ) 
}

export default Grid;